import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import ReceiveImg from '../assets/receive.png';
import { createInvoice, createURLInvoice } from '../app/routes.js';
import Invoices from './Invoices.js';
import InvoiceQR from './modals/InvoiceQR.js';
import InvoiceURL from './modals/InvoiceURL.js';

export function InvoiceIcon(props) {
  const { t } = useTranslation();

  return (
    <div onClick={props.open} className="box inactive">
      <h3 style={{ margin: '1px 0', fontSize: '17px' }}>{t('Invoice')}</h3>
      <img id="iconA" src={ReceiveImg} style={{ display: 'block', height: '30px', margin: '3px auto' }}></img>
    </div>
  );
}

function Invoice() {
  const { t } = useTranslation();
  const [invoices, setInvoices] = useState(false);
  const [qrAmount, setQrAmount] = useState(0);
  const [qrMemo, setQrMemo] = useState('');
  const [urlAmount, setUrlAmount] = useState(0);
  const [urlMemo, setUrlMemo] = useState('');
  const [qrModal, setQrModal] = useState(false);
  const [urlModal, setUrlModal] = useState(false);
  const [data, setData] = useState(null);
  const [qrLoading, setQrLoading] = useState(false);
  const [urlLoading, setUrlLoading] = useState(false);

  const handleCreateInvoice = async () => {
    if (qrAmount <= 0) return alert('Please enter a valid amount.');
    setQrLoading(true);
    const data = await createInvoice(qrAmount, qrMemo);
    setData(data);
    setQrModal(true);
    setQrLoading(false);
  };

  const handleCreateURLInvoice = async () => {
    if (urlAmount <= 0) return alert('Please enter a valid amount.');
    setUrlLoading(true);
    const url = await createURLInvoice(urlAmount, urlMemo);
    setUrlModal(true);
    setData(url);
    setUrlLoading(false);
  };

  const handleOpenInvoices = () => {
    setUrlModal(false);
    setInvoices(true);
  };

  return (
    <>
      <div id='a' className='box active'>
        <h3>{t('Invoice')}</h3>

        <p>Sorry, we've suspended this service.</p>
        <p>Support email: support@piecard.co.uk</p>

        {/* <div style={{ display: 'flex', flexDirection: 'column', minHeight: 'calc(100% - 40px)', justifyContent: 'space-evenly' }}>
          <div className="createInvoice" style={{ marginTop: '10px' }}>
            <a className="subheading">{t('create_QR_code')}:</a>
            <form action="/invoiceQR" method="POST" id="formQR">
              <input className="field" id="amountQR" type="number" placeholder={t('amount') + '*'} onChange={(e) => setQrAmount(e.target.value)} required></input><br />
              <input className="field memo" id="memoQR" type="text" placeholder={t('memo')} onChange={(e) => setQrMemo(e.target.value)}></input><br />
              <div id="invoice_btn" onClick={handleCreateInvoice}>{!qrLoading ? t('create') : <span className='btnLoader'></span>}</div>
            </form>
          </div>

          <div className="createInvoice">
            <a className="subheading">{t('create_URL')}:</a>
            <form action="/invoiceURL" method="POST" id="formURL">
              <input className="field" id="amountURL" type="number" placeholder={t('amount') + '*'} onChange={(e) => setUrlAmount(e.target.value)} required></input><br />
              <input className="field memo" id="memoURL" type="text" placeholder={t('memo')} onChange={(e) => setUrlMemo(e.target.value)}></input><br />
              <div id="invoice_btn" onClick={handleCreateURLInvoice}>{!urlLoading ? t('create') : <span className='btnLoader'></span>}</div>
            </form>
          </div>
        </div> */}

      </div>

      {qrModal && <InvoiceQR data={data} setQrModal={setQrModal} />}
      {urlModal && <InvoiceURL url={data} setUrlModal={setUrlModal} openInvoices={handleOpenInvoices} />}
      {invoices && <Invoices close={() => setInvoices(false)} />}
    </>
  );
}

export default Invoice;