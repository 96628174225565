import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import BlackQRImg from '../assets/blackqr.png';
import SendImg from '../assets/send.png';
import PayQR from './modals/PayQR';
import PayUsername from './modals/PayUsername';
import PayWallet from './modals/PayWallet';

export function SendIcon(props) {
  const { t } = useTranslation();

  return (
    <div onClick={props.open} className="box inactive">
      <h3 style={{ margin: '1px 0', fontSize: '17px' }}>{t('Send')}</h3>
      <img id="iconB" src={SendImg} style={{ display: 'block', height: '30px', margin: '2px auto' }}></img>
    </div>
  );
}

function Payees(props) {
  const { t } = useTranslation();
  const [payees, setPayees] = useState([]);

  useEffect(() => {
    if (!localStorage.profile || localStorage.profile == 'null') {
      alert('Please login to view your payees');
      props.close();
    } else {
      const profile = JSON.parse(localStorage.profile);
      const payees = [];

      for (const item of profile.history) {
        console.log(item)
        if (item.client && item.amount < 0) {
          // Check if payee is already in the list
          let found = false;
          for (const payee of payees) {
            if (payee.name === item.client) {
              found = true;
              break;
            }
          }

          if (
            !found &&
            item.client !== 'wallet' &&
            item.client.length < 50 &&
            item.client !== profile.username
          ) {
            payees.push({
              name: item.client,
              date: item.date
            });
          }
        }
      }

      console.log(payees);
      setPayees(payees);
    }
  }, []);

  const selectPayee = (payee) => {
    document.getElementById('userUsername').value = payee;
    props.setUsername(payee);
    props.close();
  }

  return (
    <>
      <div className="modalContainer">
        <div className="payModal">
          <div className="modal-header">
            <h3>{t('Previous_Payees')}</h3>
            <button className="close-modal"
              style={{ width: '40px', color: '#5b4afb', background: 'none', fontSize: '20px', marginTop: '-30px' }}
              onClick={props.close}>✕</button>
          </div>
          <section className="modal-content">
            <br />
            {payees.length > 0 ? <i>{t('click_to_select')}</i> : <i>{t('no_previous_payees')}</i>}
            <br />
            <div className="payees">
              {payees.map((payee, index) => {
                return (
                  <article key={index} className="payee" onClick={() => selectPayee(payee.name)}>
                    <a className="payeeName">{payee.name}</a> <br />
                    <i>{t('last_used')}: {payee.date.toString().substring(0, 10)}</i>
                  </article>
                );
              })}
            </div>
          </section>
        </div>
      </div>

      <div onClick={() => props.setQrModal(false)} id="darkTint"></div>
    </>
  );
}

function Pay() {
  const { t } = useTranslation();
  const [payQR, setPayQR] = useState(false);
  const [payUsername, setPayUsername] = useState(false);
  const [payWallet, setPayWallet] = useState(false);
  const [amount, setAmount] = useState(0);
  const [username, setUsername] = useState('');
  const [wallet, setWallet] = useState('');
  const [memo, setMemo] = useState('');
  const [openPayees, setOpenPayees] = useState(false);

  const handlePayQR = () => {
    setPayQR(true);
  }

  const handlePayUsername = () => {
    setPayUsername(true);
  };

  const handlePayWallet = () => {
    setPayWallet(true);
  };

  return (
    <>
      <div id='b' className='box active'>
        <h3>{t('Send')}</h3>

        <p>Sorry, we've suspended this service.</p>
        <p>Support email: support@piecard.co.uk</p>

        {/* <div style={{ display: 'flex', flexDirection: 'column', minHeight: 'calc(100% - 40px)', justifyContent: 'space-evenly' }}>
          <div className="createPayment" style={{ marginTop: '10px' }}>
            <a className="subheading">{t('scan_QR_code')}:</a>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <p id="payment_sentence">{t('scan_message')}</p>
              <img src={BlackQRImg} id="qrImg"></img>
            </div>
            <button onClick={handlePayQR} id="btn-scan-qr">{t('open_camera')} <span id="camSpan" className="fa fa-video-camera"></span></button>
          </div>

          <div className="createPayment">
            <a className="subheading">{t('send_to_username')}:</a>
            <form action="/invoice" method="POST" id="usernameForm">
              <input className="field" id="amountUsername" type="number" placeholder={t('amount') + '*'} onChange={(e) => setAmount(e.target.value)} required></input><br />
              <input className="field" id="userUsername" type="text" placeholder={t('enter_username')} onChange={(e) => setUsername(e.target.value)} style={{ marginBottom: '1px' }} required></input><br />
              {
                username.length == 0 &&
                <>
                  <a id='payeeSplitter'><span /> {t('or')} <span /></a>
                  <div id="payeeUsername" onClick={() => setOpenPayees(true)}>{t('choose_previous_payee')} <i className="fa fa-caret-down"></i></div>
                </>
              }
              <input className="field" id="memoUsername" type="text" placeholder={t('memo')} onChange={(e) => setMemo(e.target.value)}></input><br />
              <input type="button" value={t('send')} id="invoice_btn" onClick={handlePayUsername}></input>
            </form>
          </div>
        </div>

        <div className='createPayment'>
          <a className="subheading">{t('send_via_wallet_address')}:</a>
          <form id='walletFrom'>
            <input className="field" id="amountWallet" type="number" placeholder={t('amount') + '*'} onChange={(e) => setAmount(e.target.value)} required></input><br />
            <input className='field' id='walletAddress' type="text" placeholder={t('wallet_address') + '*'} onChange={(e) => setWallet(e.target.value)} required></input><br />
            <input className="field" id="memoWallet" type="text" placeholder={t('memo')} onChange={(e) => setMemo(e.target.value)}></input><br />
            <input type="button" value={t('send')} id="invoice_btn" onClick={handlePayWallet}></input>
          </form>
        </div> */}

      </div>

      {payQR && <PayQR setPayQR={setPayQR} />}
      {openPayees && <Payees close={() => setOpenPayees(false)} setUsername={setUsername} />}
      {payUsername && <PayUsername amount={amount} username={username} memo={memo} setPayUsername={setPayUsername} />}
      {payWallet && <PayWallet amount={amount} wallet={wallet} memo={memo} setPayWallet={setPayWallet} />}
    </>
  );
}

export default Pay;