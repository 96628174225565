import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { addPassword, logout, openBrowser } from '../app/routes.js';
import logo from '../assets/whiteLogo.png';
import Info from './Info.js';
import Invoices from './Invoices.js';
import Translate from './Translate.js';
import Login from './Login.js';

export function DesktopNav() {
  const { t } = useTranslation();
  const [invoices, setInvoices] = useState(false);
  const [translate, setTranslate] = useState(false);
  const [login, setLogin] = useState(false);
  const [authed, setAuthed] = useState(false);

  useEffect(() => {
    if (sessionStorage.userSession) setAuthed(true);
    window.addEventListener('auth', function () {
      if (sessionStorage.userSession) setAuthed(true);
      else setAuthed(false);
    });
  }, []);

  const openLogin = () => {
    setLogin(true);
  };

  const openTranslate = () => {
    setTranslate(true);
  }

  const openTab = (tab) => {
    if (window.location.pathname !== '/')
      window.location.href = '/?tab=' + tab;
    if (tab == 'account' && !sessionStorage.userSession) {
      setLogin(true);
    }
    else {
      window.dispatchEvent(new CustomEvent('tab', { detail: { tab: tab } }));
    }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('tab');
    if (tab) setTimeout(() => openTab(tab), 100);
  }, []);

  return (
    <>
      <div className='navBar'>

        <i onClick={() => window.location.href = '/'} style={{ float: 'left', cursor: 'pointer' }}><img src={logo} alt='Piecard' className='logo' /></i>
        <a onClick={() => openTab('pay')}>{t('Send')}</a>
        <a onClick={() => openTab('invoice')}>{t('Invoice')}</a>
        <a onClick={() => openTab('account')}>{t('Account')}</a>
        <a onClick={openTranslate}><i className='fa fa-language'></i></a>
        <a onClick={() => window.location.href = '/faqs'}><i className='fa fa-question-circle'></i></a>
        {authed === false && <a onClick={openLogin}><i className="fa fa-sign-in"></i></a>}
        {authed === true && <a onClick={logout} aria-hidden="true"><i className="fa fa-sign-out"></i></a>}

      </div>

      {window.innerWidth > 620 && <div className='navBarBg'></div>}

      {(login) && <div id="darkTint"></div>}

      {invoices === true && <Invoices close={() => setInvoices(false)} />}
      {translate === true && <Translate close={() => setTranslate(false)} />}
      {login === true && <Login close={() => setLogin(false)} />}
    </>
  );
}

function Nav() {
  const { t } = useTranslation();
  const [menu, setMenu] = useState(false);
  const [info, setInfo] = useState(false);
  const [invoices, setInvoices] = useState(false);
  const [translate, setTranslate] = useState(false);
  const [login, setLogin] = useState(false);
  const [authed, setAuthed] = useState(false);
  const [hasPassword, setHasPassword] = useState(false);

  useEffect(() => {
    if (sessionStorage.userSession) setAuthed(true);
    window.addEventListener('auth', function () {
      if (sessionStorage.userSession) setAuthed(true);
      else setAuthed(false);
      if (localStorage.password == 'true') setHasPassword(true);
    });
  }, []);

  const toggleMenu = () => {
    setMenu(!menu);
  };

  const openLogin = () => {
    if (window.innerWidth < 900) setMenu(false);
    setLogin(true);
  };

  const openInfo = () => {
    if (window.innerWidth < 900) setMenu(false);
    setInfo(true);
  }

  const openInvoices = () => {
    if (window.innerWidth < 900) setMenu(false);
    setInvoices(true);
  }

  const openTranslate = () => {
    if (window.innerWidth < 900) setMenu(false);
    setTranslate(true);
  }

  return (
    <>
      <div className={`navBar ${menu === true && 'navBarOpen'}`}>
        <i className={`toggleNav fas fa-bars ${menu === false && 'navActive'}`} id="openNav" onClick={toggleMenu}></i>
        <i className={`toggleNav fas fa-times ${menu === true && 'navActive'}`} id="closeNav" onClick={toggleMenu}></i>

        <img src={logo} onClick={() => window.location.href = '/'} alt="logo" id="navBarLogo" />
      </div>
      {window.innerWidth > 620 && <div className={`navBarBg ${menu === true && 'navBarOpen'}`}></div>}

      <div className={`popup ${menu === true && 'navOpen'}`}>
        <span id="navDiv"></span><br />
        {authed === false && <><a className="navOption" onClick={openLogin}><i className="fas fa-key tab"></i> {t('Login')}</a><br /></>}
        {(/*navigator.userAgent.toLowerCase().indexOf("pibrowser") >= 0 &&*/ authed === true) && <><a className="navOption" onClick={() => addPassword()}><i className="fas fa-key tab"></i> {hasPassword === false ? `${t('Enable_password_login')}` : `${t('Change_password')}`}</a><br /></>}
        {/* {navigator.userAgent.toLowerCase().indexOf("pibrowser") < 0 && <><a className="navOption" onClick={openBrowser}><i className="fa tab" style={{ marginLeft: '5px', marginRight: '5px', fontSize: '30px' }}>ℼ</i> {t('Open_in_Pi_Browser')}</a><br /></>} */}
        <a className="navOption" onClick={() => window.location.reload()}><i className="fa tab">&#xf021;</i> {t('Refresh')}</a><br />
        <a className="navOption" onClick={openInvoices}><i className="tab fas fa-file-invoice"></i> {t('View_outstanding_invoices')}</a><br />
        <a className="navOption" onClick={openTranslate}><i className="tab fa fa-language"></i> {t('Translate_page')}</a><br />
        {/* <a className="navOption" onClick={() => window.location.href = 'https://gateway.piecard.app'}><i className="tab fa fa-code"></i> {t('Developers')}</a><br /> */}
        <a className="navOption" onClick={openInfo}><i className="fa tab">&#xf05a;</i> {t('Info')}</a><br />
        <a className="navOption" onClick={() => window.location.href = '/faqs'}><i className="tab fa fa-question-circle"></i> FAQs</a><br />
        <a className="navOption" href="mailto:support@piecard.app" onClick={() => alert(t('Please_email') + ' support@piecard.app')}><i
          className="fa tab fas fa-message"></i> {t('Contact_support')}</a><br />
        { /* <a class="tab fa-solid fa-share-nodes" onclick="refer()"><i style="font-size: 15px; font-family: 'Fredoka', sans-serif; font-weight: normal">Refer user</i></a><br> */}
        {(authed === true /*&& navigator.userAgent.toLowerCase().indexOf("pibrowser") < 0*/) && <><a className="navOption" onClick={logout} aria-hidden="true"><i className="fa fa-sign-out tab"></i> {t('Logout')}</a><br /></>}
        <span id="error_log"></span>
      </div>

      {(menu === true && window.innerWidth < 900) && <div onClick={toggleMenu} id="tint"></div>}

      {(login) && <div id="darkTint"></div>}

      {info === true && <Info close={() => setInfo(false)} />}
      {invoices === true && <Invoices close={() => setInvoices(false)} />}
      {translate === true && <Translate close={() => setTranslate(false)} />}
      {login === true && <Login close={() => setLogin(false)} />}
    </>
  );
}

export default Nav;